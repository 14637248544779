// console.log('Vite ⚡️ Rails');
// console.log(
//   'Visit the guide for more information: ',
//   'https://vite-ruby.netlify.app/guide/rails',
// );

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

import '~/controllers';
import Steven from '~/services/steven';

document.addEventListener('turbo:load', (event) => {
  const pathname = new URL(event.detail.url).pathname;
  Steven.pageView({ pathname, url: event.detail.url });
});
