import { v4 as uuidv4 } from 'uuid';

const selectBaseUrl = () => {
  switch (process.env.NODE_ENV) {
    case 'production':
      return 'https://app.companycam.com';
    case 'qa':
      return 'https://qa.companycam.com';
    default:
      return 'http://localhost:3000';
  }
};

const postToSteven = (body) => {
  return fetch(`${selectBaseUrl()}/trusty_api/steven`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
};

const getStevenSession = () => {
  const storedItem = window.localStorage.getItem('stevenSession');
  const currentSession = storedItem && JSON.parse(storedItem);

  if (Date.now() > currentSession?.expiry) {
    return [currentSession, true];
  }

  return [currentSession, false];
};

// event type constants
const ELEMENT_CLICKED = 'element_clicked';
const USER_INVITED = 'user_invited';
const PAGE_VIEW = 'page_view';
const SIGNUP_STEP = 'signup_step';
const ENTITY_CRUD = 'entity_crud';
const TRUSTY_CONTACT_ACTION = 'trusty_contact_action';

// element type constants
export const ELEMENT_TYPES = {
  BUTTON: 'button',
  LINK: 'link',
  NOTIFICATION: 'notification',
};

// Looking for non-code docs? Check out the Event Logging guide (https://www.notion.so/companycam/Event-Logging-4d785a8e2aec4290b11576f18c5859c1?pvs=4)
// or the User Manual (https://www.notion.so/companycam/Steven-A-User-s-Manual-1b8cf505e2e74156a77b34d04c434f13?pvs=4)

const start = () => {
  const [currentSession, isExpired] = getStevenSession();

  if (!currentSession || isExpired) {
    const newSession = {
      deviceId: currentSession?.deviceId || uuidv4(),
      sessionId: uuidv4(),
      expiry: Date.now() + 86400000, // tomorrow
      /**
       * While optional chaining returns undefined, localStorage (or more directly, Storage) returns null for missing keys.
       * previousSessionId must be either a UUIDv4 or null, so this implementation should be safe (and functionally match mobile Steven).
       */
      previousSessionId: currentSession?.sessionId,
      sessionIndex: (currentSession?.sessionIndex || 0) + 1,
      eventIndex: 0,
    };

    window.localStorage.setItem('stevenSession', JSON.stringify(newSession));

    return newSession;
  }

  return currentSession;
};

const incrementEventIndex = () => {
  const currentSession = JSON.parse(
    window.localStorage.getItem('stevenSession'),
  );

  if (!currentSession) {
    const newSession = start();
    newSession.eventIndex += 1;
    return newSession;
  }

  currentSession.eventIndex += 1;
  window.localStorage.setItem('stevenSession', JSON.stringify(currentSession));
  return currentSession;
};

function getCoordinates() {
  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 600000,
  };

  return new Promise((resolve) => {
    const resolveAsReject = () => resolve({});
    window.navigator.geolocation.getCurrentPosition(
      resolve,
      resolveAsReject,
      options,
    );
  });
}

async function getMetadata() {
  let referrer = document.referrer;
  if (window.top) {
    referrer = window.top.document.referrer;
  } else if (window.parent) {
    referrer = window.parent.document.referrer;
  }

  const metadata = {
    deviceScreenHeight: window.innerHeight,
    deviceScreenWidth: window.innerWidth,
    dvce_created_tstamp: Date.now(),
    page_url: window.location.href,
    page_urlhost: window.location.hostname,
    page_urlpath: window.location.pathname,
    page_urlport: window.location.port,
    page_urlscheme: window.location.protocol.slice(0, -1), // drop the trailing colon
    page_referrer: referrer,
    platform: 'web',
  };

  const { state } = await navigator.permissions.query({ name: 'geolocation' });
  const isGeolocationGranted = state === 'granted';

  if (isGeolocationGranted) {
    const geolocation = await getCoordinates();
    const geoData = {
      geo_latitude: geolocation?.coords?.latitude,
      geo_longitude: geolocation?.coords?.longitude,
    };

    return { ...metadata, ...geoData };
  }

  return metadata;
}

async function tellSteven(name, data) {
  const sessionData = incrementEventIndex();
  const metadata = await getMetadata();
  const body = {
    tracked_event: {
      name,
      data: {
        ...metadata,
        ...sessionData,
        ...data,
      },
    },
  };

  postToSteven(body);
}

/**
 * Events you can use where needed!
 * Add new events here (and to the default export) - just remember to tellSteven about them.
 */

/**
 * Use case: tracking when the user taps/clicks on a UI element, and only that the element was tapped/clicked.
 *
 * @param {Object} eventDetails - describes the context of the triggered event
 * @param {string} eventDetails.id - unique id of the element that was clicked
 * @param {string} eventDetails.content - English copy on the element that was clicked, this should be your messages.yourMessage.defaultMessage reference (used with react-intl)
 * @param {string} eventDetails.type - tagName of the element that was clicked
 * @param {string} eventDetails.action - name of the action being called
 */
function elementClicked({ id, content, type, action }) {
  tellSteven(ELEMENT_CLICKED, {
    id,
    content,
    type,
    action,
  });
}

/**
 * Use case: tracking when a user has successfully sent an invitation to an invitee.
 *
 * @param {string 128} invitee_id - invitee ID
 * @param {('manage_users'|'mentions'|'dash'|'api'|'contacts')} source - source of the invitation action
 */
function userInvited({ invitee_id, source }) {
  tellSteven(USER_INVITED, {
    invitee_id,
    source,
  });
}

/**
 * Use case: tracking where the user ends up after a page has loaded.
 * If you aren't modifying the ccEventsOnPageLoad integration, you probably want a different event.
 *
 * @param {string} pathname - path of the page the user was routed to
 * @param {string} url - full URL of the page the user was routed to
 */
function pageView({ pathname, url }) {
  tellSteven(PAGE_VIEW, { pathname, url });
}

/**
 * Use case: tracking that the user reaching, but not necessarily getting past, any and all steps in the signup flow.
 *
 * @param {string 255} step_name - a step of the sign-up process
 */
function signupStep({ step_name }) {
  tellSteven(SIGNUP_STEP, { step_name });
}

/**
 * Use case: tracking when a user creates, updates, or deletes an entity.
 * @param {string} entity_type - string representing the type of entity being created, updated, or deleted - should correspond to a database model
 * @param {string} entity_id - database id of the entity being created, updated, or deleted
 * @param {string} action - type of action being performed on the entity (create, update, delete)
 * @param {string | null} source - optional, location where the event was triggered from. For example 'template'
 * @param {string | null} updated_action - optional, a more granular description of the update. For example 'name_changed' or 'status_changed'
 */
function entityCrud({
  entity_type,
  entity_id,
  action,
  source,
  updated_action,
}) {
  tellSteven(ENTITY_CRUD, {
    entity_type,
    entity_id,
    action,
    source,
    updated_action,
  });
}

/**
 *
 * @param {string} id - Unique identifier of the element that was clicked
 * @param {string | null} content - English user-facing copy on the element that was clicked
 * @param {("phone"|"text"|"email")} type - Type of contact that was clicked (phone, text, email)
 * @param {string} action - What product behavior the event is trying to report
 * @param {string} trusty_company_id_contacted - The trusty id of the contractor that was contacted
 * @param {boolean} is_cocam_company_contacted - Indicates if the contractor contacted is a cocam company
 * @param {string | null} cocam_company_id_contacted - The cocam company id of the contractor that was contacted
 */
function trustyContactAction({
  id,
  content,
  type,
  action,
  trusty_company_id_contacted,
  is_cocam_company_contacted,
  cocam_company_id_contacted,
}) {
  tellSteven(TRUSTY_CONTACT_ACTION, {
    id,
    content,
    type,
    action,
    trusty_company_id_contacted,
    is_cocam_company_contacted,
    cocam_company_id_contacted,
  });
}

export default {
  elementClicked,
  pageView,
  start,
  userInvited,
  signupStep,
  entityCrud,
  trustyContactAction,
};
